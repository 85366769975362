allcaps = function (main, $) {

    var g = main.gui = main.gui || {};

    var timeoutID;

    g.init = function () {

        // clicking on the 'doubles' button when double amounts is active
        $('body').on('click', '.jsAddToListParent', function (e) {
            highlightCorrectDoubleAmount($(this).parent(), $(this).data('amount'));
        });

        $('body').on('click', '.jsAddToList, .jsAddToListSub', function (e) {
            e.preventDefault();

            var $btn = $(this);
            var remove = false;
            var amount = 1;
            var activeClass;

            // in double dropdown
            if ($btn.hasClass('jsAddToListSub')) {
                amount = parseInt($btn.data('amount'));
                if (amount === 0) {
                    remove = true;
                }
                $btn = $btn.closest('.btn-group').find('.jsAddToListParent').first();
            } else {
                activeClass = $btn.data('successClass') ? $btn.data('successClass') : 'btn-success';
                remove = $btn.hasClass(activeClass);
            }

            if ($btn.attr('disabled')) {
                return;
            }
            ;

            var id = $btn.data('id');
            var list = $btn.data('list');
            activeClass = $btn.data('successClass') ? $btn.data('successClass') : 'btn-success';
            var url = remove ? main.utils.route('front.ajax.list.remove_cap') : main.utils.route('front.ajax.list.add_cap');
            url = url.replace('_LIST_', list).replace('_CAP_', id);
            if (!remove && amount > 1) {
                url += '/' + amount;
            }

            // disable btn
            var prevBtnContent = $btn.html();
            $btn.attr('disabled', 'disabled').html('<i class="loader" aria-hidden="true"></i>');

            // send
            $.post(url)
                .success(function (data) {
                    var error = main.utils.getAjaxError(data);
                    if (error) {
                        main.utils.showError(error);
                        return;
                    }

                    if ($btn.data('amount') != undefined) {
                        $btn.data('amount', amount);
                        prevBtnContent = '<i class="fa fa-handshake-o"></i>';
                        if(amount > 1) {
                            prevBtnContent = amount + 'x';
                        }
                    }

                    $btn.toggleClass(activeClass, !remove);
                    main.utils.showSuccess(data.data.message);

                })
                .error(function (data) {
                    main.utils.showError(main.utils.getAjaxError(data));
                })
                .always(function (data) {
                    // remove spinner, and enable button
                    $btn.removeAttr('disabled').html(prevBtnContent);

                    // added to/removed from extra list
                    if (data.data.html_badge) {
                        // update extra list badge data
                        $btn.find('.jsExtraListBadge').html(data.data.html_badge);

                        // update toolbar extra list button
                        // toggle it 'on' if it's at least 1 extra list
                        var amount = $('.jsAddToList.' + activeClass).length;
                        var extraListButtonContent = '<i class="fa fa-list"></i>';
                        if(amount > 1) {
                            extraListButtonContent = amount + 'x';
                        }
                        $('.jsAddToOtherList[data-id="' + id + '"]')
                            .toggleClass('btn-lists-success', ($('.jsAddToList.' + activeClass).length > 0))
                            .html(extraListButtonContent);
                    }

                });
        });

        autoGrowTextAreas();
        initSelects();
        initJumpToPagination();
    };

    g.initExtraListButtons = function(){

        //plugin bootstrap minus and plus
        //http://jsfiddle.net/laelitenetwork/puJ6G/
        $('.btn-number').click(function (e) {
            e.preventDefault();

            fieldName = $(this).attr('data-field');
            type = $(this).attr('data-type');
            var input = $("input[name='" + fieldName + "']");
            var currentVal = parseInt(input.val());
            if (!isNaN(currentVal)) {
                if (type === 'minus') {

                    if (currentVal > input.attr('min')) {
                        input.val(currentVal - 1).change();
                    }
                    if (parseInt(input.val()) === input.attr('min')) {
                        $(this).attr('disabled', true);
                    }

                } else if (type === 'plus') {

                    if (currentVal < input.attr('max')) {
                        input.val(currentVal + 1).change();
                    }
                    if (parseInt(input.val()) === input.attr('max')) {
                        $(this).attr('disabled', true);
                    }

                }
            } else {
                input.val(0);
            }
        });
        $('.jsExtraListAmount')
            .focusin(function () {
                $(this).data('oldValue', $(this).val());
            })
            .keydown(function (e) {
                // Allow: backspace, delete, tab, escape, enter and .
                if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 190]) !== -1 ||
                    // Allow: Ctrl+A
                    (e.keyCode === 65 && e.ctrlKey === true) ||
                    // Allow: home, end, left, right
                    (e.keyCode >= 35 && e.keyCode <= 39)) {
                    // let it happen, don't do anything
                    return;
                }
                // Ensure that it is a number and stop the keypress
                if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                    e.preventDefault();
                }
            })
            .on('change',function (e) {
            e.preventDefault();

            var $btn = $(this);
            var minValue =  parseInt($(this).attr('min'));
            var maxValue =  parseInt($(this).attr('max'));
            var amount = parseInt($(this).val());

            var name = $(this).attr('name');
            if(amount >= minValue) {
                $(".btn-number[data-type='minus'][data-field='"+name+"']").removeAttr('disabled')
            } else {
                alert('Sorry, the minimum value was reached');
                $(this).val($(this).data('oldValue'));
            }
            if(amount <= maxValue) {
                $(".btn-number[data-type='plus'][data-field='"+name+"']").removeAttr('disabled')
            } else {
                alert('Sorry, the maximum value was reached');
                $(this).val($(this).data('oldValue'));
            }

            var remove = amount <= 0;
            var activeClass;
            var container = $btn.closest('.jsExtraList');
            var title = container.find('.list-title');

            if ($btn.attr('disabled')) {
                return;
            }

            var id = $btn.data('id');
            var list = $btn.data('list');
            activeClass = $btn.data('successClass') ? $btn.data('successClass') : 'btn-success';
            var url = remove ? main.utils.route('front.ajax.list.remove_cap') : main.utils.route('front.ajax.list.add_cap');
            url = url.replace('_LIST_', list).replace('_CAP_', id);
            if (!remove && amount > 1) {
                url += '/' + amount;
            }

            // disable btn
            if(title.html() !== '<i class="loader" aria-hidden="true"></i>'){
                var prevBtnContent = title.html();
                title
                    .attr('disabled', 'disabled')
                    .html('<i class="loader" aria-hidden="true"></i>');
            }

            // send
            $.post(url)
                .success(function (data) {
                    var error = main.utils.getAjaxError(data);
                    if (error) {
                        main.utils.showError(error);
                        return;
                    }


                    container.toggleClass(activeClass, !remove);
                    main.utils.showSuccess(data.data.message);

                })
                .error(function (data) {
                    main.utils.showError(main.utils.getAjaxError(data));
                })
                .always(function (data) {
                    // remove spinner, and enable button
                    title.removeAttr('disabled').html(prevBtnContent);

                    // added to/removed from extra list
                    if (data.data.html_badge) {
                        // update extra list badge data
                        container.find('.jsExtraListBadge').html(data.data.html_badge);

                        // update toolbar extra list button
                        // toggle it 'on' if it's at least 1 extra list
                        var amount = 0; // $('.jsExtraList.' + activeClass).length;
                        $('.jsExtraList.list-group-item-success  .jsExtraListAmount').each(function(){
                            amount += parseInt($(this).val());
                        })
                        var extraListButtonContent = '<i class="fa fa-list"></i>';
                        if (amount > 1) {
                            extraListButtonContent = amount + 'x';
                        }
                        $('.jsAddToOtherList[data-id="' + id + '"]')
                            .toggleClass('btn-lists-success', ($('.jsExtraList.' + activeClass).length > 0))
                            .html(extraListButtonContent);
                    }

                });
        });
    }

    g.initMassActionExtraListButtons = function(){

        $('body').on('click', '.jsAddToOtherList', function (e) {
            var modal = $('#selectListModal');
            // change all 'types' of lists to 'add' or 'remove'
            modal.find('.jsSelectExtraList').data('type', $(this).data('type'))

            // show correct modal title
            modal.find('.jsSelectListModalTitle').hide();
            modal.find('.jsSelectListModalTitle[data-type="'+$(this).data('type')+'"]').show();

            // hide the list we're currently working in
            var listToHideId = $(this).data('listToHide');
            if(listToHideId){
                modal.find('.jsSelectExtraList').show();
                modal.find('.jsSelectExtraList[data-id="'+listToHideId+'"]').hide();
            }

        });
    }

    g.initFancybox = function(){

        Fancybox.bind("[data-fancybox]", {
            // Your options
        });
    }

    function highlightCorrectDoubleAmount($dropdown, amount) {
        $dropdown.find('.jsAddToListSub').removeClass('btn-doubles-success');
        // show/hide remove option if needed
        $dropdown.find('.jsAddToListSub[data-amount="0"]').toggle(amount > 0);

        if (amount === 0) {
            return;
        }
        $dropdown.find('.jsAddToListSub[data-amount="' + amount + '"]').toggleClass('btn-doubles-success', true);
    }

    function initSelects(){
        $('.select2').select2({
            lang: $('html').attr('lang'),
            minimumResultsForSearch: 20,
        });

        $('.select2').parent().find(".select2").css({'width': '100%'});
    }

    function initJumpToPagination(){

        $('.jsJumpToPagination').keydown(function(evt) {
            // If enter is pressed
            if (evt.keyCode === 13) {
                evt.preventDefault();

                // If changes have been made to the input's value,
                //  blur() will result in a change event being fired.
                this.blur();
            }
        });
        $('.jsJumpToPagination').on('blur focusout', function(){
            var value = parseInt($(this).val());
            if(parseInt($(this).data('old')) !== value && value < parseInt($(this).attr('max'))){
                $(this).attr('disabled', true);
                window.location.replace($(this).data('url').replace('_PAGE_', value));
            }
        })
    }

    var autoGrowTextAreas = function () {
        $('textarea').on('change keyup', function (e) {
            g.autoGrowTextarea(e.target);

        }).trigger('change');

        /* see https://github.com/markmead/alpinejs-textarea-autogrow/blob/main/src/index.js */
    };

    g.autoGrowTextarea = function(textarea){
        if (textarea.clientHeight < textarea.scrollHeight) {
            textarea.style.height = textarea.scrollHeight + "px";
            if (textarea.clientHeight < textarea.scrollHeight) {
                textarea.style.height = (textarea.scrollHeight * 2 - textarea.clientHeight) + "px";
            }
        }
    }

    /**
     * Prevent default event behaviour
     *
     * @param event
     */
    main.pde = function (event) {
        if (event.preventDefault) {
            event.preventDefault();
        }

        if (event.stopPropagation) {
            event.stopPropagation();
        }
    };

    return main;

}(window.allcaps || {}, jQuery);
